const baseUrl = 'https://api.mercadohit.com';


export default {
  // Endpoints
  loginEndpoint: `${baseUrl}/login`,
  usuarioCompositorCadastroEndpoint: `${baseUrl}/usuario/compositor`,
  usuarioConfirmarCadastroEndpoint: `${baseUrl}/usuario/confirmar-email`,
  usuarioEsqueciSenhaEndpoint: `${baseUrl}/senha/esqueci`,
  usuarioNovaSenhaEndpoint: `${baseUrl}/senha/novo`,
  usuarioTrocarSenhaEndpoint: `${baseUrl}/senha/trocar`,
  usuarioCompositorDadosEndpoint: `${baseUrl}/usuario/compositor`,
  usuarioCompradorDadosEndpoint: `${baseUrl}/usuario/comprador`,
  usuarioGestorDadosEndpoint: `${baseUrl}/usuario`,
  usuarioEnderecoDadosEndpoint: `${baseUrl}/endereco`, 
  usuarioEnderecoEditarEndpoint: `${baseUrl}/endereco`,
  usuarioEditarCompositorEndpoint: `${baseUrl}/usuario/compositor`,
  usuarioEditarCompradorEndpoint: `${baseUrl}/usuario/comprador`,
  usuarioEditarGestorEndpoint: `${baseUrl}/usuario/alterar-gestor`,
  registerEndpoint:  '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // Endpoints - Totalizador - Compositor

  dashboardCompositorCarregarSaldoEndpoint: `${baseUrl}/compositor/dashboard/saldo`,
  dashboardCompositorTotalizadorEndpoint: `${baseUrl}/compositor/dashboard/totalizador`,

  // Endpoints - Totalizador - Comprador

  dashboardCompradorTotalizadorEndpoint: `${baseUrl}/comprador/dashboard/totalizador`,

  // Endpoints - Favoritos - Comprador

  hitCompradorFavoritoEndpoint: `${baseUrl}/favorito`,
  hitCompradorFavoritoListaEndpoint: `${baseUrl}/favorito/comprador`,


  // Endpoints - Hit
  hitEndpoint: `${baseUrl}/hit`,
  hitCompletoEndpoint: `${baseUrl}/hit/completo`,
  hitListaEndpoint: `${baseUrl}/compositor/hit`,
  hitTemasListaEndpoint: `${baseUrl}/tema`,
  hitEstilosListaEndpoint: `${baseUrl}/estilo`,
  hitCadastrarEndpoint: `${baseUrl}/hit`,
  hitCompositorEditarEndpoint: `${baseUrl}/hit`,
  hitUploadEndpoint: `${baseUrl}/hit/upload`, // ID PASSANDO NA URL DO HitCadastrar
  hitCompositorEditarUploadEndpoint: `${baseUrl}/hit/reenvio`, // ID PASSANDO NA URL DO HitCadastrar
  hitDownloadEndpoint: `${baseUrl}/hit/download`, // ID PASSANDO NA URL 
  hitGestorEditarEndpoint: `${baseUrl}/hit/gestor`,

  // Endpoints - Playlist
  playlistEndpoint: `${baseUrl}/playlist`,
  playlistListarEndpoint: `${baseUrl}/playlist`,
  playlistCriarEndpoint: `${baseUrl}/playlist`,
  playlistRemoverEndpoint: `${baseUrl}/playlist`,
  playlistHitsEndpoint: `${baseUrl}/playlist/hits`,
  playlistAddHitEndpoint: `${baseUrl}/playlist/adicionar-hit`,
  playlistRemoverHitEndpoint: `${baseUrl}/playlist/remover`,


  // Endpoints - Aprovações
  // Hits
  hitAprovacoesListaEndpoint: `${baseUrl}/aprovar/hit`,
  hitAprovarEndpoint: `${baseUrl}/aprovar/hit`,
  hitLetraEditarEndpoint: `${baseUrl}/hit/atualizar-letra`,
  hitReprovarEndpoint: `${baseUrl}/aprovar/hit`,
  
  // Campanhas
  campanhaAprovacoesListaEndpoint: `${baseUrl}/aprovar/campanha`,
  campanhaAprovarEndpoint: `${baseUrl}/aprovar/campanha`,
  campanhaReprovarEndpoint: `${baseUrl}/aprovar/campanha`,

  // Usuários
  usuarioTrocarSenhaEndpoint: `${baseUrl}/usuario`,


  // Compositores

  usuariosCompositoresListaEndpoint: `${baseUrl}/usuario/compositor/todos`,
  usuariosCompositorEditarEndpoint: `${baseUrl}/usuario/compositor`,
  usuarioCompositorEndpoint: `${baseUrl}/usuario`,
  usuarioCompositorEditarEndpoint: `${baseUrl}/usuario/compositor`,
  usuarioCompositorSaldoEditarEndpoint: `${baseUrl}/usuario/compositor/saldo`,
  usuarioCompositorSaldoHistoricoEndpoint: `${baseUrl}/usuario/compositor/saldo`,
  usuarioCompositorHitsListaEndpoint: `${baseUrl}/hit/compositor`,
  
  // Compradores
  usuariosCompradoresListaEndpoint: `${baseUrl}/usuario/comprador/todos`,
  usuariosCompradorEditarEndpoint: `${baseUrl}/usuario/comprador`,
  usuarioCompradorEndpoint: `${baseUrl}/usuario`,
  usuarioCompradorCadastrarEndpoint: `${baseUrl}/usuario/comprador`,
  usuarioCompradorEditarEndpoint: `${baseUrl}/usuario/comprador`,

  
  // Lista campanhas
  usuarioCompradorAudicoesListaEndpoint: `${baseUrl}/campanha/comprador`,
  
  // Endpoints - Hit - Compositor
  hitCompositorListaEndpoint: `${baseUrl}/compositor/hit`,
  hitCompositorIdEndpoint: `${baseUrl}/compositor/hit`,
  hitCompositorPendenciasEndpoint: `${baseUrl}/compositor/pendencias`,
  hitCampanhaEnviarEndpoint: `${baseUrl}/campanha/participar`,
  hitCompositorCampanhaParticipacaoEndpoint: `${baseUrl}/compositor/campanha/participacao`,  
  
  // Endpoints - Hit - Comprador
  hitCompradorListaEndpoint: `${baseUrl}/comprador/hit`,
  hitPerteceCompradorListaEndpoint: `${baseUrl}/comprador/meus-hits`,
  hitPertenceCompradorInfoEndpoint: `${baseUrl}/comprador/meus-hits/info`,
  hitHistoricoCompradorInfoEndpoint: `${baseUrl}/comprador/meus-hits/historico`,
  hitFavoritarEndpoint: `${baseUrl}/favorito`,

  // Endpoints - Campanhas

  campanhaEndpoint: `${baseUrl}/campanha`,
  campanhaListaEndpoint: `${baseUrl}/comprador/campanha`,
  campanhaPendenciasEndpoint: `${baseUrl}/comprador/pendencias`,
  campanhaCadastrarEndpoint: `${baseUrl}/campanha`,
  campanhaEditarEndpoint: `${baseUrl}/campanha`,
  campanhaCapaUploadEndpoint: `${baseUrl}/campanha/upload`, // ID PASSANDO NA URL DA CampanhaCadastrar
  campanhaCapaEditarUploadEndpoint: `${baseUrl}/campanha/reenvio`, // ID PASSANDO NA URL DA CampanhaCadastrar
  campanhaCompradorIdEndpoint: `${baseUrl}/comprador/campanha`,
  campanhaCompositorHitsIdEndpoint: `${baseUrl}/comprador/campanha/hits`,
  campanhasAtivasCompositorListaEndpoint: `${baseUrl}/compositor/campanha/ativas`,
  campanhasProximasCompositorListaEndpoint: `${baseUrl}/compositor/campanha/proximas`,
  campanhasQtdHitsPorCampanhaEndpoint: `${baseUrl}/campanha/comprador/audicao`,
  campanhasListaHitsPorCampanhaEndpoint: `${baseUrl}/campanha/comprador/hits`,

  // Endpoints - Campanhas - Compositor

  campanhaCompositorListaEndpoint: `${baseUrl}/compositor/campanha`,
  campanhaCompositorIdEndpoint: `${baseUrl}/compositor/campanha`,
  campanhaTodosCompositorListaEndpoint: `${baseUrl}/compositor/campanha/todos`,

  // Endpoints - Propostas - Compositor/Comprador

  propostasListaEndpoint: `${baseUrl}/proposta`,
  negociacoesListaEndpoint: `${baseUrl}/proposta/historico`,
  negociacaoCadastrarEndpoint: `${baseUrl}/negociacao/iniciar`,
  negociacaoPropostaFinalizarEndpoint: `${baseUrl}/negociacao/finalizar`,
  negociacaoPropostaNegociarEndpoint: `${baseUrl}/negociacao/negociar`,
  negociacaoPropostaUpgradeEndpoint: `${baseUrl}/negociacao/upgrade`,

  // Endpoints - Configurações
  hitTemaCadastrarEndpoint: `${baseUrl}/tema`,
  hitTemaCadastrarListaEndpoint: `${baseUrl}/tema`,
  hitEstiloCadastrarEndpoint: `${baseUrl}/estilo`,
  hitEstiloCadastrarListaEndpoint: `${baseUrl}/estilo`,
  hitPerfilCadastrarEndpoint: `${baseUrl}/perfil`,
  hitPerfilAdicionarAbilityEndpoint: `${baseUrl}/perfil`,
  hitPerfilPerfilAbilityEndpoint: `${baseUrl}/perfil`,


  // Endpoints -  Ability
  abilityListaEndpoint: `${baseUrl}/ability`,
  abilityListaTotalEndpoint: `${baseUrl}/ability/total`,

  // Endpoints - Gestão
  gestaoNegociacaoListarEndpoint: `${baseUrl}/proposta/gestao`,
  gestaoNegociacaoDetalheEndpoint: `${baseUrl}/proposta/gestao/detalhe`, // ID PASSANDO NA URL 
  gestaoNegociacaoUploadDocEndpoint: `${baseUrl}/proposta/gestao/upload-documentacao`, // ID PASSANDO NA URL 
  gestaoNegociacaoExcluirDocEndpoint: `${baseUrl}/proposta/gestao/excluir-documentacao`, // ID PASSANDO NA URL 
  gestaoNegociacaoAtualizarStatusEndpoint: `${baseUrl}/proposta/gestao/atualizar`, // ID PASSANDO NA URL 
  gestaoBuscarTodosHitsEndpoint: `${baseUrl}/gestao/dashboard/hit/total`, 
  gestaoBuscarTodosCompradoresEndpoint: `${baseUrl}/gestao/dashboard/usuario/total/comprador`, 
  gestaoBuscarTodosCompositoresEndpoint: `${baseUrl}/gestao/dashboard/usuario/total/compositor`, 

  // Endpoints - Assinatura
  assinaturaPlanosListaEndpoint: `${baseUrl}/plano`,
  assinaturaCadastrarEndpoint: `${baseUrl}/assinatura`,
  assinaturaCancelarEndpoint: `${baseUrl}/assinatura/cancelar`,

  // Endpoints - Pix

  assinaturaPixGerarEndpoint: `${baseUrl}/pix`,
  assinaturaPixStatusPagamentoEndpoint: `${baseUrl}/pix/verifica-pagamento`,

  // Endpoints - Endereço
  compositorEnderecoEndpoint: `${baseUrl}/endereco`,
  compositorAssinaturaEndpoint: `${baseUrl}/assinatura`,

  // Endpoints - Reproducão
  contarReproducaoEndpoint: `${baseUrl}/reproducao`,
  contarQtdReproducaoEndpoint: `${baseUrl}/reproducao/usuario`,
  contarQtdReproducaoTotalEndpoint: `${baseUrl}/reproducao/usuario/total`,


  // Endpoints - Parametro
  listarParametrosEndpoint: `${baseUrl}/parametro`,
  alterarSituacaoParametrosEndpoint: `${baseUrl}/parametro`,
  alterarDadosParametrosEndpoint: `${baseUrl}/parametro/alterar`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

}
